<!--
 * @Author: qinlifang 1171533972@qq.com
 * @Date: 2023-09-17 02:54:04
 * @LastEditors: xxxxxy_112 xxxxxy_112@163.com
 * @LastEditTime: 2024-05-11 21:34:01
 * @FilePath: \cyberdao-home\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <!-- <NavBar
      v-if="$route.meta.hideTabBar && !isMobile"
      :key="lang"
      class="NavBar"
    /> -->
    <router-view></router-view>
    <!-- <Over v-if="$route.meta.hideTabBar && !isMobile" @changeLang="changeLang" /> -->
  </div>
</template>

<script>
import DevicePixelRatio from "./utils/devicePixelRatio";
// import NavBar from "./components/NavBar.vue";
import { Toast } from "vant";
export default {
  // components: {
  //   NavBar,

  // },
  data() {
    return {
      lang: "",
      // ismobile:''
      windowWidth: window.innerWidth,
      isMobile: null, // 添加一个初始值
    };
  },
  computed: {
    isMobile: {
      get() {
        return this.windowWidth < 960;
      },
      set(value) {
        console.log("New mobile value:", value);
        // 更新 isMobile 的值并触发组件的重新渲染
        this.onResize();
      },
    },
  },
  methods: {
    onResize() {
      // 当窗口大小变化时，更新 isMobile 的值并触发组件的重新渲染
      this.isMobile = window.windowWidth < 960;
    },
    changeLang(val) {
      this.lang = val;
    },
  },

  beforeDestroy() {
    // 在组件销毁前移除事件监听器，防止内存泄漏
    window.removeEventListener("resize", this.onResize);
  },
  created() {
    new DevicePixelRatio().init();
    window.addEventListener("resize", this.onResize); // 在创建组件时添加窗口大小改变的事件监听器
    this.onResize(); // 调用 onResize 方法进行初始检测
    // this._isMobile()
    window.addEventListener("online", () => {
      // Toast({
      //   message: '网络已连接',
      //   duration: 2000,
      // })
      // console.log('网络已连接')
    });
    window.addEventListener("offline", () => {
      Toast({
        message: "网络已断开",
        duration: 2000,
      });
      // console.log('网络已断开')
    });

    if (navigator.onLine) {
      // Toast({
      //   message: '网络已连接',
      //   duration: 2000,
      // })
      // console.log('网络已连接1')
    } else {
      Toast({
        message: "网络已断开",
        duration: 2000,
      });
      // console.log('网络已断开1')
    }
  },
  // mounted () {
  //   // 根据不同路由跳转不同页面
  //   if (this._isMobile()) {
  //     console.log('手机端')
  //     this.$router.replace('/stakemobile')
  //   } else {
  //     console.log('pc端')
  //     this.$router.replace('/stake')
  //   }
  // },
  //  methods: {
  //   // 判断是否是手机端，如果是，返回true
  //   _isMobile () {
  //     let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  //     return flag
  //   }
  // }
};
</script>

<style lang="less" scoped></style>
