import { now } from '@/utils/helpers'

export default new class {
    /**
     * 地址KEY
     * @type {string}
     * @private
     */
    _addressKey = 'address'


    _dao_beginner = 'dao_beginner'



    /**
     * 令牌KEY
     * @type {string}
     * @private
     */
    _tokenKey = 'token'

    /**
     * 过期时间KEY
     * @type {string}
     * @private
     */
    _expiredKey = 'expired_at'

    /**
     * 获取地址
     * @returns {string}
     */
    getAddress() {
        return localStorage.getItem(this._addressKey) || ''
    }

    /**
     * 获取令牌
     * @returns {string}
     */
    getToken() {
        return localStorage.getItem(this._tokenKey) || ''
    }

    getIsdao() {
        return localStorage.getItem(this._dao_beginner) || ''
    }


    /**
     * 设置令牌
     * @param address
     * @param token
     * @param expiredAt
     */
    setToken(address, token, expiredAt, dao_beginner) {
        localStorage.setItem(this._addressKey, address)
        localStorage.setItem(this._tokenKey, token)
        localStorage.setItem(this._expiredKey, expiredAt)
        localStorage.setItem(this._dao_beginner, dao_beginner)
    }

    /**
     * 移除令牌
     */
    removeToken() {
        localStorage.removeItem(this._addressKey)
        localStorage.removeItem(this._tokenKey)
        localStorage.removeItem(this._expiredKey)
        localStorage.removeItem(this._dao_beginner)
    }

    /**
     * 是否过期
     * @returns {boolean}
     */
    isExpired() {
        if (!this.getToken()) {
            return false
        }
        const expiredAt = localStorage.getItem(this._expiredKey)
        if (!expiredAt) {
            return false
        }
        return now() > expiredAt
    }
}