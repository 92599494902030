import Web3, { utils } from 'web3'
import i18n from '@/utils/lang'
import { Toast } from 'vant'

export default new class {
    units = {
        0: 'wei',
        3: 'kwei',
        6: 'mwei',
        9: 'gwei',
        12: 'szabo',
        15: 'finney',
        18: 'ether',
        21: 'kether',
        24: 'mether',
        27: 'gether',
        30: 'tether'
    }

    /**
     * ETH请求实例
     * @returns {Promise<Eth>}
     * @private
     */
    async _eth() {
        const provider = window.ethereum
        if (provider) {
            return new Web3(provider).eth
        } else {
            return Promise.reject(i18n.t('web3.no_install'))
        }
    }

    /**
     * 链接钱包
     * @returns {Promise<Modules.Eth>}
     */
    async link() {
        const eth = await this._eth()
        try {
            await eth.requestAccounts()
            return eth
        } catch (err) {
            const error = err.innerError || err
            switch (error.code) {
                case 4001:
                    return Promise.reject(i18n.t('web3.link_error.reject'))
                case -32002:
                    return Promise.reject(i18n.t('web3.link_error.waiting'))
                default:
                    return Promise.reject(i18n.t('web3.link_error.fail'))
            }
        }
    }

    /**
     * 获取第一个地址
     * @returns {Promise<string|string>}
     */
    async address() {
        const eth = await this._eth()
        const accounts = await eth.getAccounts()
        return accounts[0] || ''
    }

    /**
     * 签名
     * @param address
     * @param str
     * @returns {Promise<*>}
     */
    async sign(address, str) {
        const eth = await this._eth()
        try {
            return await eth.personal.sign(str, address, '')
        } catch (err) {
            const error = err.innerError || err
            switch (error.code) {
                case 4001:
                    return Promise.reject('您已取消签名')
                default:
                    return Promise.reject('签名失败')
            }
        }
    }

    /**
     * 切换网络
     * @param chainId 0x...
     * @returns {Promise<never>}
     */
    async switchChain(chainId) {
        if (!window.ethereum) {
            return Promise.reject(i18n.t('web3.switch_error.fail'))
        }

        try {
            await window
                .ethereum
                .request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: chainId }]
                })
            await window
                .ethereum
                .request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: chainId }]
                })
        } catch (err) {
            switch (err.code) {
                case 4001:
                    return Promise.reject(i18n.t('web3.switch_error.reject'))
                case 4902:
                case -32603:
                    return Promise.reject(i18n.t('web3.switch_error.none', {network: chainId}))
                default:
                    return Promise.reject(i18n.t('web3.switch_error.fail'))
            }
        }
    }

    /**
     * 发送交易
     * @param chainId 0x...
     * @param from 0x...
     * @param to 0x...
     * @param value 0x...
     * @param data 0x...
     * @returns {Promise<unknown>}
     */
    async sendTransaction(chainId, from, to, value = '0x0', data = '0x') {
        const eth = await this._eth()

        await this.switchChain(chainId)

        const gasPrice = utils.toHex(await eth.getGasPrice())

        return new Promise((resolve, reject) => {
            eth.sendTransaction({
                from: from,
                to: to,
                value: value,
                gas: utils.toHex(100000),
                gasPrice: gasPrice,
                data: data
            }).on('transactionHash', (hash) => {
                resolve(hash)
            }).on('error', err => {
                switch (err.code) {
                    case 4001:
                        reject(i18n.t('web3.transfer_error.reject'))
                        break
                    default:
                        reject(i18n.t('web3.transfer_error.fail'))
                }
            })
        })
    }
    // tino  链上获取合约余额
    async getBalanceOf(chainId, address, contract, decimals) {
        const addressStr = address.toLowerCase().substring(2, address.length).padStart(64, '0')
        const eth = await this._eth()
        await this.switchChain(chainId)
        const result = await eth.call({
            to: contract,
            data: `0x70a08231${addressStr}`
        })
        const loading = Toast.loading({
            message: 'Loading',
            duration: 0,
            forbidClick: true,
        })
    try {
        const wei = utils.toBN(result)
        loading.clear()
        return utils.fromWei(wei,this.units[decimals])
      
    } catch (error) {
        loading.clear()
        console.log(error);
        return '0'
    }
    }
   // 授权
    async  approve(chainId,contract,token,decimals,address,compared) {
        console.log(compared, typeof compared)
        const loading = Toast.loading({
            message: 'Loading',
            duration: 0,
            forbidClick: true,
        })
        const addressStr = address.toLowerCase().substring(2, address.length).padStart(64, '0')
        const contractStr = contract.toLowerCase().substring(2, contract.length).padStart(64, '0')
      
        const eth = await this._eth()
        await this.switchChain(chainId)
        const result = await eth.call({ to: token, data: `0xdd62ed3e${addressStr}${contractStr}` })
        try {
            loading.clear()
            if(utils.toBN(result).gte(utils.toBN(utils.toWei(compared, this.units[decimals])))) { 
                return
            }
        } catch (error) {
            console.log(error);
            loading.clear()
            throw Error('Failed')
        }
        const gasPrice = utils.toHex(await eth.getGasPrice())
        return new Promise((resolve, reject) => {
            eth.sendTransaction({
            from: address,
            to: token,
            value: '0x0',
            gas: utils.toHex(60000),
            gasPrice: gasPrice,
            data: `0x095ea7b3${contractStr}ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff`
          }).on('receipt', ({ status }) => {
            if (status) {
              resolve()
            } else {
              reject('Failed')
            }
          }).on('error', err => {
            switch (err.code) {
              case 4001:
                reject('Approval has been cancelled')
                break
              default:
                reject('Failed')
            }
          })
        })
      }

}
