export default {
    web3: {
        no_install: '没有安装钱包',
        link_error: {
            waiting: '连接等待确认中，请检查您的钱包软件以确认',
            reject: '您已取消连接',
            fail: '连接失败',
        },
        sign_error: {
            reject: '您已取消签名',
            fail: '签名失败',
        },
        switch_error: {
            reject: '您已取消切换网络',
            none: '没有添加{network}网络',
            fail: '网络切换失败'
        },
        transfer_error: {
            reject: '您已取消转账',
            fail: '转账失败'
        },
        logout_title: '是否断开钱包连接？',
        logouted: '您已断开钱包的连接！',
        loading: "加载中",
        loading2: "下拉即可刷新",


    },
    home: {
        CryptoIndex: "加密指数基金",
        Web3innovative: "Web3创新基金，是你加密投资的最优选",
        Wallet: "Connect",
        Wallets: "Connect",
        Total: "资产总值",
        Subscribing: "Subscribe中",
        Subscrib: "Subscribe",
        MAXIFund: "MAXI基金",
        Notless: "不低于",
        Notless1: "Subscribe数量不低于",
        over: "已结束",
        MAX: "最大",
        AUM: "基金规模",
        MAXIPrice: "价格",
        Minsubscription: "最低认购量",
        APY: "收益率",
        Contactus: "联系我们",
        TermsofUse: "使用条款",
        PrivacyPolicy: "隐私说明",
        V1: "V1.0.0版",
    },
    FundDetails: {
        MyShares: "我的份额",
        Redeem: "赎回",
        seven: "7日",
        thirty: "30日",
        Custom: "自定义",
        NetAssets: "基金资产总值",
        MAXIPerformance: "价格走势",
        Current: "当前价格",
        Holdings: "投资标的",
        Symbol: "标的",
        Quantity: "占比",
        Amount: "金额",
        Send: "发送",
        Shares: "Share",
        SubscriptionAmt: "Invested",
        SubscriptionFee: "Subscribe费",
        Total: "共计",
        Cancel: "取消",
        Confirm: "确认",
        next: "下一步",
        date: "日期选择",
        dates: "日期选择不能超过30天",
    },
    Analysis: {
        PnL: "盈亏分析",
        Balance: "余额",
        Balance1: "我的资产",
        NetAssets: "资产总值",
        CumulativePnL: "累计收益",
        DailyPnL: "单日盈亏",
        Expenses: "费用明细",
        Subscribe: "Subscribe费",
        Redeem: "赎回费",
        Manage: "管理费",
        Performance: "业绩报酬",
        Performance1: "业绩报酬",
        Time: "时间",
        Order: "订单号",
        Type: "类型",
        SubscriptionAmt: "Subscribe金额",
        ExpenseRatio: "费率",
        Feevalue: "金额",
        PerShare: "Price",
    },
    RedemptioRecord: {
        History: "Subscribe/赎回记录",
        Subscribe: "Subscribe",
        Redeem: "赎回",
        MAXIFund: "MAXI基金",
        Subscribing: "Subscribe中",
        Type: "类型",
        SubscriptionAmt: "Subscribe金额",
        Shares: "份额",
        OrderNo: "订单号",
        Date: "日期",
        Success: "成功",
        Fail: "失败",
        NoData: "暂无记录",
        Redeeming: "赎回中",
        PerShare: "预估单价",
        PerShare1: "单价",
        RedemptionFee: "预估赎回费",
        RedemptionFee1: "赎回费",
        PerformanceFee: "预估业绩报酬",
        PerformanceFee1: "业绩报酬",
        Received: "预估到账金额",
        Received1: "到账金额",
        ID: "编号",
        Yield: "收益率",
        ExpenseRatio: "费率",
        Cancel: "取消",
        Authorize: "签名授权",
        Copied: "复制成功",
        CopyFailed: "复制失败"
    },
    AboutMAX1: {
        Subscription: "Subscribe说明 ",
        Welcometo: "欢迎参与Matrix Fund管理的",
        Welcometo4: "",
        Welcometo1: "，请您仔细阅读以下关键信息：",
        Welcometo2: "欢迎参与Matrix Fund管理的MAX1基金，请您仔细阅读以下关键信息：",
        Welcometo3: "欢迎参与Matrix Fund管理的MAX1基金，请您仔细阅读以下关键信息：",
        innovative: "是一支创新加密基金，以代币作为投资者的投资份额。代币价值由基金投资的数字资产总和支撑。",
        issuance: "代币的发行价为",
        issuance3: "USDT，募集到的资金将投资于主流数字资产。",
        issuance2: "可转账或赎回，以满足投资者的流动性需求。",
        query: "3. 投资人可通过区块浏览器查询METF的交易细节并监督资金流向。",
        query1: "的交易细节并监督资金流向。",
        MatrixFund: "4. Matrix Fund由行业专业投资人士负责，具有多年数字货币投资经验，年化收益率（APR）预计在30%-200%之间，具有较高的收益潜力，为您创造更多价值。",
        Fundexpenses: "5. 费率说明",
        SubscriptionFee: "Subscribe费：投资者发起Subscribe时需额外支付Subscribe费，根据投资量实行阶梯费率。",
        ManagementFee: "管理费：1.5%/年，每月1日计提管理费，以上月末基金净值为基础计算。",
        PerformanceFee: "业绩报酬：业绩报酬实行阶梯费率，仅收取盈利部分。收益率每增长20%，计提一次业绩报酬（METF价格以7日均价为准）。",
        yied: "收益率",
        ratyio: "业绩报酬",
    },
    RedemptionFee: {
        Redemption: "赎回说明",
        RedemptionFees: "赎回费",
        Reriod: "持有期限",
        ratio: "费率",
        superb: "Matrix Fund是您投资Web3的理想选择，我们致力于为投资者提供透明、安全、富有潜力的数字资产投资机会。加入我们，共同分享Web3的无限可能性！",
        redeem: "感谢您选择Matrix Fund投资Web3领域。在进行赎回操作时，请注意以下几点：",
        Redemptiontime: "1. 赎回时间：请您在工作时间申请赎回，到账时间因区块链处理速度而有所差异，预计在30分钟内完成，请您耐心等待。",
        Minimum: "2. 最低赎回份额：您正在赎回的",
        Minimum1: "设有最低赎回份额",
        Minimum2: "，若您持有的",
        Minimum3: "数量小于最低赎回份额，则需一次性全部赎回。",
        certain: "3. 赎回费：持有期限未满365天时，需要收取一定赎回费用，请您查阅《费率说明》。",
        Based: "4. 业绩报酬：Matrix Fund将按您的赎回份额出售对应比例的数字资产，并计算您需要缴纳的业绩报酬。",
        dedicat: "如果您在赎回过程中遇到任何问题或需要帮助，请随时联系我们的客户服务团队，我们将竭诚为您服务。",

    },
    other: {
        Redeeming: "赎回中",
        Complete: "赎回成功",
        Failed: "赎回失败",
        Completes: "已成功发起Subscribe请求，请确认转账！",
        Completes4: "已成功发起赎回请求，请确认转账！",
        Completes2: "Subscribe成功",
        Completes1: "已发起转账，请稍后确认Subscribe状态！",
        Completes3: "已发起转账，请稍后确定赎回状态！",
        Faileds: "Subscribe失败",
        lessthan: "不低于",
        price: "价格",
        RedemptionFee: "赎回费",
        Performance: "业绩报酬",
        Received: "预估到账金额",
        SubscriptionFee: "Subscribe费",
        ManagementFee: "管理费",
        PerformanceFee: "业绩报酬",
        BaseAmt: "计提基数",
        Shares: "份额",
        NetAssets: "订单价值",
        RedemptionAmt: "赎回总额",
        available: "余额",
        enter: "请输入Subscribe数量",

        Disconnect: "Disconnect",
        MaxRedemption: "赎回金额",
        balance: 'Insufficient balance',
        balance1: '份额不足',
        value: "资产值",
        incom: "收益值",
        contvalue: "数值",
        contvalue1: "数值",
        enters: "请输入赎回数量",
        Notless1: "赎回数量不低于",
        start: "开始",
        end: "结束",
        p1: "gas费不足，地址至少持有",
        p2: "当前不允许赎回",
    },
    Detail: {
        p1: " 一、Subscribe费：投资者发起Subscribe时额外支付的费用，根据投资量收取阶梯费率。",
        p2: "单笔Subscribe金额",
        p3: "费率",
        p4: " 二、管理费：1.5%/年，管理费每月1日计提，以每月末基金净值为基础计算。",
        p5: " 三、赎回费：投资者持有期限不足365天时将收取一定赎回费。",
        p6: " 持有期限",
        p7: " 费率",
        p8: " 天",
        p9: "四、业绩报酬：业绩报酬实行阶梯费率，仅收取盈利部分。收益率每增长20%，计提一次业绩报酬（METF价格以7日均价为准）。",
        p10: " 收益率",
        p11: " 业绩报酬",
        p13: " 费率说明",
        p14: " 使用条款",
        p15: " 隐私说明",



    }
}